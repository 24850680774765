<template>
  <div class="col-12 pt-4 d-none d-lg-block">
    <div class="row m-0 align-items-center">
      <div class="col-auto px-0">
        <img :src="logo" class="img-fluid cursor logo" :class="{logo: isUK, logoZa: !isUK}" @click="goTo('')">
      </div>
      <div class="col-lg px-0 align-self-end">
        <MainMenu />
      </div>
      <div class="col-auto px-0">
        <TopMenuIconLinks />
      </div>
      <div class="col-auto pe-0">
        <TopMenuDonateButton />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    MainMenu: defineAsyncComponent(() => import('./MainMenu.vue')),
    TopMenuIconLinks: defineAsyncComponent(() => import('../components/TopMenuIconLinks.vue')),
    TopMenuDonateButton: defineAsyncComponent(() => import('../components/TopMenuDonateButton.vue'))
  },
  name: 'TopMenuLayoutDesktop',
  data () {
    return {
      logo: process.env.VUE_APP_LOGO
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
.logo  {
  max-height: 58px;
  max-width: 180px;
  width: 180px;
}
.logoZa  {
  max-height: 75px;
  width: 180px;
}
</style>
